'use client'

import { useState } from 'react'

const Paragraph1 = () => {
  return (
    <div>
      <h1 className='mb-1 mt-2 flex-1 text-[32px] font-bold leading-10'>
        Lasoo - Shop Online and Save Australia
      </h1>
      <h3 className='my-5 w-full flex-1 text-xl font-bold text-primary-dark'>
        Why Wing It When You Can Lasoo It!
      </h3>
      At Lasoo, huge, exclusive savings on everything from the latest gadgets to
      cosy home essentials are just the beginning. On one single platform,
      discover, compare and buy specials from Australia’s biggest and best
      retailers. Explore our fantastic deals and discover why we’re the go-to
      destination for savvy shoppers.
    </div>
  )
}
const Paragraph2 = ({ show }: { show: Boolean }) => {
  return (
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    <div className={`${show ? 'block' : 'hidden'}`}>
      <h2 className='my-5 w-full flex-1 text-xl font-bold text-primary-dark'>
        Why Shop with Lasoo?
      </h2>
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        Shop Smarter, Not Harder
      </h3>
      We’ve curated an incredible range of products across{' '}
      <a
        className='text-blue-600 underline visited:text-purple-600 hover:text-blue-800'
        href='https://www.lasoo.com.au/categories/electronics'
      >
        electronics
      </a>
      , beauty,{' '}
      <a
        className='text-blue-600 underline visited:text-purple-600 hover:text-blue-800'
        href='https://www.lasoo.com.au/categories/toys'
      >
        toys
      </a>
      , and so much more from sought-after brands. Lasoo makes it easy to find
      what you need at prices you’ll love, all with the convenience of shopping
      from home.
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        A Shopping Experience Like No Other
      </h3>
      Forget about the usual online shopping hassle. Our platform is designed to
      be user-friendly and fun, offering an engaging digital catalogue
      experience. From click to cart, you’re taken care of with fast and free
      shipping and responsive customer service.
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        Trusted by Shoppers
      </h3>
      With a top-notch Trustpilot score and a swarm of happy customers,
      confidence in our service is high. Unlike other platforms that might leave
      you guessing, we pride ourselves on transparency and satisfaction.
      <h2 className='my-5 w-full flex-1 text-xl font-bold text-primary-dark'>
        Features Making Shopping a Breeze
      </h2>
      At Lasoo, we make your shopping simpler and smarter. With location-based
      searches, you can spot deals right around the corner, making sure you’re
      always in on local bargains. Our Wishlist keeps you updated on sales for
      everything you’ve got your eye on, complete with alerts so you never miss
      a deal on your favourite goodies. Effortlessly sift through sales with
      filters for category, price, retailer, or brand, finding just what you
      need in a snap. Our Discovery feature lets you browse loads of digital
      catalogues to find awesome deals, and the Comparison feature ensures
      you’re getting the lowest price available. Ready to buy? Our Buy feature
      lets you grab goodies from multiple retailers in one simple checkout
      process.
      <h2 className='my-5 w-full flex-1 text-xl font-bold text-primary-dark'>
        Discover More Than Just Products
      </h2>
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        For Every Space and Style
      </h3>
      Whether refreshing your living room with chic{' '}
      <a
        className='text-blue-600 underline visited:text-purple-600 hover:text-blue-800'
        href='https://www.lasoo.com.au/categories/furniture'
      >
        furniture
      </a>{' '}
      or gearing up with the latest tech, our specials cover everything from{' '}
      <a
        className='text-blue-600 underline visited:text-purple-600 hover:text-blue-800'
        href='https://www.lasoo.com.au/categories/furniture/office-furniture/office-chairs'
      >
        office chairs
      </a>{' '}
      to{' '}
      <a
        className='text-blue-600 underline visited:text-purple-600 hover:text-blue-800'
        href='https://www.lasoo.com.au/categories/furniture/beds/mattresses'
      >
        mattresses
      </a>{' '}
      and{' '}
      <a
        className='text-blue-600 underline visited:text-purple-600 hover:text-blue-800'
        href='https://www.lasoo.com.au/categories/furniture/outdoor-furniture'
      >
        outdoor furniture
      </a>
      . Get inspired with pieces that transform spaces.
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        For Every Face and Place
      </h3>{' '}
      Upgrade your beauty routine with exclusive{' '}
      <a
        className='text-blue-600 underline visited:text-purple-600 hover:text-blue-800'
        href='https://www.lasoo.com.au/categories/beauty'
      >
        beauty products
      </a>{' '}
      or get{' '}
      <a
        className='text-blue-600 underline visited:text-purple-600 hover:text-blue-800'
        href='https://www.lasoo.com.au/categories/jewellery'
      >
        jewellery
      </a>{' '}
      to complement your newest outfit. For the fun-loving and the
      young-at-heart, our toys section is packed with games that guarantee
      smiles.
      <h2 className='my-5 w-full flex-1 text-xl font-bold text-primary-dark'>
        Lasoo It: Your Favourite Brands in One Place
      </h2>
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        Electronics
      </h3>{' '}
      Score major deals on the latest electronics. Whether you’re tech-savvy or
      just looking for the newest gadget, Lasoo offers discounts on top brands
      like Samsung, Apple, and Google.
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        Furniture
      </h3>
      Revamp your living space with stylish furniture finds. From modern sofas,
      comfy{' '}
      <a
        className='text-blue-600 underline visited:text-purple-600 hover:text-blue-800'
        href='https://www.lasoo.com.au/categories/furniture/beds'
      >
        beds
      </a>{' '}
      or classic dining sets, find everything to make your house a home without
      the hefty price tag.
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        Outdoor Furniture
      </h3>
      Make the most of the outdoors with our range of outdoor furniture.
      Discover great deals on patio sets, garden benches, and more to create
      your perfect backyard retreat.
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        Jewellery
      </h3>{' '}
      Adorn yourself with our exclusive jewellery collection. From statement
      pieces to everyday essentials, find sparkling deals that don’t break the
      bank.
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        Toys
      </h3>{' '}
      Keep the kids entertained with fun and educational toys from Lasoo.
      Explore a wide range of toys for all ages at prices that make playtime
      even more enjoyable.
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        Beds and Mattresses
      </h3>{' '}
      Invest in your rest with our selection of beds and mattresses. Shop top
      brands and enjoy significant savings on items designed for a good night’s
      sleep.
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        Beauty Products
      </h3>{' '}
      Enhance your beauty routine with our range of beauty products. From
      skincare to fragrances, find everything you need to look and feel your
      best.
      <h3 className='my-3 w-full flex-1 text-xl font-bold text-gray-500'>
        Baby Products
      </h3>{' '}
      Care for your little ones with the best{' '}
      <a
        className='text-blue-600 underline visited:text-purple-600 hover:text-blue-800'
        href='https://www.lasoo.com.au/categories/baby'
      >
        baby products
      </a>{' '}
      on the market. From diapers to furniture, get everything you need for baby
      care at unbeatable prices.
      <h2 className='my-5 w-full flex-1 text-xl font-bold text-primary-dark'>
        Join Our Community
      </h2>{' '}
      Become part of the Lasoo family and enjoy the perks of being a
      first-to-know shopper. Sign up for our newsletter, join us on Facebook,
      and stay tuned for exclusive offers and updates.
      <h2 className='my-5 w-full flex-1 text-xl font-bold text-primary-dark'>
        Don’t Pay Fool Price, Shop Lasoo
      </h2>{' '}
      Why wait? Discover how much you can save with Lasoo now; our seamless
      shopping experience brings you the best for less. Start exploring today
      and see why thousands choose to Lasoo it. Sign up, shop, and start saving
      with Lasoo—where every deal is a steal!
    </div>
  )
}

const HomePageTextInternal = () => {
  const [showParagraph2, setShowParagraph2] = useState(false)
  const [buttonTitle, setButtonTitle] = useState(true)

  return (
    <div className='relative my-5 w-full max-w-[100vw] grid-flow-row lg:max-w-page'>
      <Paragraph1 />
      <Paragraph2 show={showParagraph2} />
      <div
        className='mt-1 cursor-pointer text-[12px] font-semibold leading-4 text-primary-dark underline'
        onClick={() => {
          setShowParagraph2(!showParagraph2)
          setButtonTitle(!buttonTitle)
        }}
      >
        {buttonTitle ? 'Read More' : 'Collapse'}
      </div>
    </div>
  )
}

export const HomePageText = () => {
  return <HomePageTextInternal />
}
