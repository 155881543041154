import dynamic from 'next/dynamic'

export default ({
  open,
  url,
  onClose,
}: {
  open: boolean
  url?: string
  onClose?: () => void
}) => {
  if (!open) return null
  const WebPlayer = dynamic(async () => await import('./web-player-modal'))
  return (
    <div
      role='presentation'
      className='fixed inset-0 z-[101] size-full bg-black/50'
      onClick={onClose}
    >
      <WebPlayer url={url} open={open} onClose={onClose} />
    </div>
  )
}
