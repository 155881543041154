'use client'

import { Icon } from '@/icons/icon'
import Link from 'next/link'
import { type ReactNode } from 'react'
import { ResolvedImage } from '@/components/utility/image'
import { routeCalculatorRelative } from '@/lib/route-calculator'

export interface WishNotificationProps {
  src?: string | null
  text?: string
}

const WishListContentDiv = ({ children }: { children: ReactNode }) => (
  <div className='flex h-[50px] w-full cursor-pointer flex-row items-center sm:w-[464px]'>
    {children}
  </div>
)

const ProductImage = ({ children }: { children: ReactNode }) => (
  <div className='relative left-4 flex size-10 max-h-10 min-h-10 min-w-10 max-w-10 flex-col rounded-lg bg-accent1-dark'>
    {children}
  </div>
)

const ContentText = ({ children }: { children: ReactNode }) => (
  <div className='ml-11 mr-8 text-sm font-bold text-primary-contrast-text'>
    {children}
  </div>
)

const IconDiv = ({ children }: { children: ReactNode }) => (
  <div className='absolute left-auto right-4'>{children}</div>
)

export const WishListNotificationContent = ({
  src,
  text,
}: WishNotificationProps) => {
  return (
    <Link
      prefetch={false}
      href={routeCalculatorRelative.wishlist}
      data-testid='wishlist-content-div'
    >
      <WishListContentDiv>
        {src != null && (
          <ProductImage>
            <ResolvedImage
              src={src}
              className='size-10 max-h-none max-w-none object-cover'
              data-testid='product-img'
              height={40}
              width={40}
              alt={text as string}
            />
          </ProductImage>
        )}
        <ContentText>{text}</ContentText>
        <IconDiv>
          <Icon customColor='white' name='love-it-bold' />
        </IconDiv>
      </WishListContentDiv>
    </Link>
  )
}
