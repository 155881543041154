/* eslint-disable @typescript-eslint/naming-convention */
'use client'

import { type Breakpoint } from '@/components/utility/size/show-on-size'
import Link from 'next/link'
import { type ReactNode } from 'react'
import { sendGTMEvent } from '@next/third-parties/google'
import { type CatalogueProps } from './catalogue-item-types'

import { CircleLogo } from '@/components/elements/logos/circle'
import { useIsWindowBelowBreakpoint } from '@/lib/utils/use-window-size'
import { getWidth } from './catalogue-item-functions'

const getSmall = (breakPointForSizeTransition?: Breakpoint) => {
  switch (breakPointForSizeTransition) {
    case 'xs':
      return 'block xs:hidden'
    case 'sm':
      return 'block sm:hidden'
    case 'md':
      return 'block md:hidden'
    case 'lg':
      return 'block lg:hidden'
    case 'xl':
      return 'block xl:hidden'
  }
}

const getLarge = (breakPointForSizeTransition?: Breakpoint) => {
  switch (breakPointForSizeTransition) {
    case 'xs':
      return 'hidden xs:block'
    case 'sm':
      return 'hidden sm:block'
    case 'md':
      return 'hidden md:block'
    case 'lg':
      return 'hidden lg:block'
    case 'xl':
      return 'hidden xl:block'
  }
}

const getLogoSize = (breakPointForSizeTransition?: Breakpoint) => {
  switch (breakPointForSizeTransition) {
    case 'xs':
      return 'h-12 xs:h-12  top-[-26px] xs:top-[-18px]'
    case 'sm':
      return 'h-8 sm:h-12 top-[-26px] sm:top-[-18px]'
    case 'md':
      return 'h-8 md:h-12 top-[-26px] md:top-[-18px]'
    case 'lg':
      return 'h-8 lg:h-12 top-[-26px] lg:top-[-18px]'
    case 'xl':
      return 'h-8 xl:h-12 top-[-26px] xl:top-[-18px]'
  }
}

export const BrandLogo = ({
  logoBackgroundColor,
  logoSrc,
  logoAlt,
  breakPointForSizeTransition = 'sm',
}: Pick<
  CatalogueProps,
  'breakPointForSizeTransition' | 'logoBackgroundColor' | 'logoSrc' | 'logoAlt'
>) => {
  const isBelowBreakpoint = useIsWindowBelowBreakpoint(
    breakPointForSizeTransition,
  )

  if (isBelowBreakpoint) {
    return (
      <div
        style={{ backgroundColor: logoBackgroundColor }}
        className={`absolute ${getLogoSize(
          breakPointForSizeTransition,
        )} ${getSmall(breakPointForSizeTransition)} rounded-half bg-white`}
      >
        <CircleLogo
          backgroundColor={logoBackgroundColor}
          src={logoSrc}
          size={'s'}
          alt={logoAlt}
        />
      </div>
    )
  }

  return (
    <div
      style={{ backgroundColor: logoBackgroundColor }}
      className={`absolute ${getLogoSize(
        breakPointForSizeTransition,
      )} ${getLarge(breakPointForSizeTransition)} rounded-half bg-white`}
    >
      <CircleLogo
        backgroundColor={logoBackgroundColor}
        src={logoSrc}
        size={'m'}
        alt={logoAlt}
      />
    </div>
  )
}

export const BaseContainer = ({
  children,
  href,
  breakPointForSizeTransition,
  productGroupId,
  displayName,
  retailerId,
  retailerDisplayName,
  isFullWidth,
  ...props
}: {
  children: ReactNode
  href: URL | string
  breakPointForSizeTransition?: Breakpoint
  productGroupId?: string
  displayName?: string
  retailerId?: string
  retailerDisplayName?: string
  'data-testid'?: string
  isFullWidth?: boolean
}) => {
  return (
    <Link
      prefetch={false}
      data-testid={props['data-testid']}
      href={href ?? ''}
      onClick={() => {
        sendGTMEvent({
          event: 'directory_search',
          clickText: 'cataloguethumbnail',
          catalogueId: productGroupId,
          catalogueName: displayName,
          retailerId,
          retailerName: retailerDisplayName,
        })
      }}
      className={`${
        isFullWidth ? 'w-full' : getWidth(breakPointForSizeTransition)
      } relative box-border block h-[254px] overflow-hidden rounded-lg border border-solid border-gray-200 bg-white no-underline`}
    >
      {children}
    </Link>
  )
}
