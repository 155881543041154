/* eslint-disable import/namespace */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { hostURL } from '@/utils/functions/url-utils'
import {
  type GetWishListsFullQuery,
  type AddToWishListMutation,
  type RemoveFromWishListMutation,
} from '../generated/graphql'
import * as Sentry from '@sentry/nextjs'

export const addToWishlistClient = async (productId?: string | null) => {
  try {
    const resp = await fetch(`${hostURL()}/api/wishlists`, {
      method: 'PUT',
      body: JSON.stringify({ productId }),
    })

    return (await resp.json()) as AddToWishListMutation
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const removeFromWishlistClient = async (productId?: string | null) => {
  try {
    const resp = await fetch(`${hostURL()}/api/wishlists`, {
      method: 'DELETE',
      body: JSON.stringify({ productId }),
    })

    return (await resp.json()) as RemoveFromWishListMutation
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const getFullWishlistClient = async () => {
  try {
    const resp = await fetch(`${hostURL()}/api/wishlists`, {
      method: 'GET',
    })

    return (await resp.json()) as false | GetWishListsFullQuery
  } catch (error) {
    Sentry.captureException(error)
  }
}
