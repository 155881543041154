export type CircleSizes = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl'

export const getCircleSize = (size?: CircleSizes) => {
  switch (size) {
    case 'xxxl':
      return 152
    case 'xxl':
      return 128
    case 'xl':
      return 88
    case 'l':
      return 64
    case 'm':
      return 48
    case 's':
      return 32
    case 'xs':
      return 24
    default:
  }
}

export type HorizontalSizes = 'xs' | 's' | 'm' | 'l' | 'xl'
