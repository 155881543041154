import { type Category, type Product } from '@/lib/generated/graphql'
import { type CategoryLevel, type CategoryLevelKey } from './category-level'

export const getCategoryName = (
  categoryLevel: number | null | undefined,
  category?: Category,
  preferFriendly?: boolean,
): string => {
  if (!category || typeof categoryLevel !== 'number') {
    return ''
  }
  if (preferFriendly && category.category_friendlyName) {
    return category.category_friendlyName
  }

  const name = category[`category_level${categoryLevel}` as CategoryLevelKey]
  return name ?? category?.category_name ?? ''
}

export const getCategoryNameByProduct = (
  product?: Product | undefined,
): string => {
  const categoryLevel = Math.min(
    product?.product_categorylevel ?? 4,
    3,
  ) as CategoryLevel
  return product?.[`product_categorylevel${categoryLevel}`] ?? ''
}
