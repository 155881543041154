'use client'

import { hostURL } from '@/utils/functions/url-utils'
import { isNullOrEmpty } from '@/utils/string-extensions'
import * as Sentry from '@sentry/nextjs'
import { type AdvertData } from '@/lib/data/products/get-advert-server'

export const getAdvertClient = async (
  advertId: string,
  retailerId: string,
): Promise<AdvertData | null> => {
  if (isNullOrEmpty(advertId)) {
    return null
  }
  try {
    const resp = await fetch(
      `${hostURL()}/api/adverts/${advertId}/${retailerId}`,
      {
        method: 'GET',
      },
    )
    return await resp.json()
  } catch (error) {
    // eslint-disable-next-line import/namespace
    Sentry.captureException(error)
  }
  return null
}

export const getAdvertsClient = async (
  advertIds: string[],
): Promise<AdvertData[] | null> => {
  if (advertIds.length === 0) {
    return null
  }
  try {
    const resp = await fetch(
      `${hostURL()}/api/adverts/list/${advertIds.join(',')}`,
      {
        method: 'GET',
      },
    )
    return await resp.json()
  } catch (error) {
    // eslint-disable-next-line import/namespace
    Sentry.captureException(error)
  }
  return null
}
