/* eslint-disable @typescript-eslint/promise-function-async */
'use client'

import { type ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

const InViewHidden = ({
  inView,
  component,
  fallbackComponent,
}: {
  inView: boolean
  component: ReactNode
  fallbackComponent: ReactNode
}) => {
  return inView ? component : fallbackComponent
}

export default function KeepOutOfViewIfNotNeeded({
  component,
  fallbackComponent,
}: {
  component: ReactNode
  fallbackComponent: ReactNode
}) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })
  return (
    <div ref={ref}>
      <InViewHidden
        inView={inView}
        component={component}
        fallbackComponent={fallbackComponent}
      />
    </div>
  )
}
