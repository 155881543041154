import { type Breakpoint } from '@/components/utility/size/show-on-size'

export const getWidth = (breakPointForSizeTransition?: Breakpoint) => {
  switch (breakPointForSizeTransition) {
    case 'xs':
      return 'w-[148px] xs:w-[184px]'
    case 'sm':
      return 'w-[148px] sm:w-[184px]'
    case 'md':
      return 'w-[148px] md:w-[184px]'
    case 'lg':
      return 'w-[148px] lg:w-[184px]'
    case 'xl':
      return 'w-[148px] xl:w-[184px]'
    default:
      return 'w-[148px]'
  }
}
