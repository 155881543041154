'use client'

import { type AdSource, type AdProps } from '@/lib/data/get-basic-ads'

import { Advertisement } from '@/components/advertisements/advertisement'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import { Suspense, use, useState } from 'react'
import { sendGTMEvent } from '@next/third-parties/google'

const getElements = (ads: AdProps[], src: AdSource) =>
  ads.map((advertisement, index) => (
    <Advertisement
      key={index}
      {...advertisement}
      isLazy={!(index === 0 && src === 'tier1')}
    />
  ))

interface AdsPaginationListProps {
  ads: Promise<AdProps[]>
  testId?: string
  className?: string
  src: AdSource
}

const AdvertisementsSkeleton = ({
  src,
  className,
}: {
  src: AdSource
  className?: string
}) => (
  <div
    className={`relative mb-8 flex h-[150px] w-[95%] justify-center self-center sm:w-fit md:h-[222px] md:w-full lg:h-[288px] ${className ?? ''} ${src === 'category' || src === 'tier1' ? 'hidden sm:flex' : ''}`}
  >
    <div className='mt-2.5 h-[150px] w-[582px] animate-pulse rounded-2xl bg-grey-200 md:h-[222px] md:w-[861px] lg:h-[288px] lg:w-[1117px]' />
  </div>
)

const AdsPaginationList = ({
  ads,
  testId,
  className,
  src,
}: AdsPaginationListProps) => {
  return (
    <Suspense
      fallback={<AdvertisementsSkeleton src={src} className={className} />}
    >
      <AdsPaginationListInternal
        ads={ads}
        testId={testId}
        className={className}
        src={src}
      />
    </Suspense>
  )
}

const AdsPaginationListInternal = ({
  ads,
  testId,
  className,
  src,
}: AdsPaginationListProps) => {
  const resolvedAds = use(ads)
  const elements = getElements(resolvedAds, src)
  const [selected, setSelected] = useState(0)
  const [emblaRef, emblaCLI] = useEmblaCarousel({ loop: true }, [
    Autoplay({
      delay: 5000,
      stopOnInteraction: false,
    }),
  ])

  if ((resolvedAds ?? []).length === 0) return null

  emblaCLI?.on('select', () => {
    setSelected(emblaCLI?.selectedScrollSnap())
  })

  return (
    <div
      className={`relative flex w-full flex-col items-center ${src === 'category' || src === 'tier1' ? 'hidden sm:flex' : ''}`}
      onClick={() => {
        sendGTMEvent({
          event: 'section_click',
          section: `advertisements - ${src}`,
          location: window.location,
        })
      }}
    >
      <div
        className={`relative flex h-[150px] w-[95%] justify-center self-center sm:w-fit md:h-[222px] md:w-full lg:h-[288px] ${className ?? ''}`}
        data-testid={testId}
      >
        {elements.length > 1 ? (
          <div
            ref={emblaRef}
            className='embla mt-3 h-[150px] w-full overflow-hidden pb-8 sm:w-[670px] md:h-[222px] md:w-[941px] lg:h-[288px] lg:w-full'
          >
            <div className='embla__container flex'>
              {elements.map((element, index) => (
                <div
                  className='embla__slide mx-10 flex flex-[0_0_100%] place-content-center first:ml-0 '
                  key={index}
                  data-testid='swiper-thumbnail'
                >
                  {element}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className='my-6 flex justify-center'>{elements[0] ?? null}</div>
        )}
      </div>
      {elements.length > 1 && (
        <div className='mt-6 flex w-full items-center justify-center'>
          {resolvedAds.map((advert, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  emblaCLI?.scrollTo(index)
                }}
                className={`mx-1 cursor-pointer transition-all ${index === selected ? 'w-8 bg-dark-dark' : 'w-2 bg-dark-dark/30'} h-2 rounded-[4px]  `}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default AdsPaginationList
