/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
'use client'

import {
  type MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { ButtonIcon } from '../elements/button-icon/button-icon'
import { type Product } from '@/lib/generated/graphql'
import { WishlistContext } from './wishlist-provider'

interface WishListLoveItProps {
  className?: string
  product: Product
}

export const WishListLoveItButton = ({
  product,
  ...props
}: WishListLoveItProps) => {
  const { inWishList, setIsOnWishList } = useContext(WishlistContext)

  const isProductInWishList = useMemo(() => {
    return inWishList(product?.product_id)
  }, [inWishList, product?.product_id])

  const [isInWishList, setIsInWishList] = useState(product.product_inWishList)

  const isDisabled = useMemo(() => {
    return isInWishList !== isProductInWishList
  }, [isInWishList, isProductInWishList])

  useEffect(() => {
    if (isInWishList !== isProductInWishList) {
      setIsInWishList(isProductInWishList)
    }

    // adding isInWishList makes the component re-render
    // and stops the heart from updating correctly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inWishList])

  const loveItOnClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation()
      setIsOnWishList(setIsInWishList, product)
    },
    [product, setIsOnWishList],
  )

  return (
    <ButtonIcon
      {...props}
      color='dark'
      iconActiveVariant='love-it-bold'
      iconVariant='love-it-regular'
      data-testid='product-love-it'
      active={isInWishList ?? false}
      onClick={loveItOnClick}
      aria-label={`Add ${product.product_name} to wishlist`}
      canActivate
      disabled={isDisabled}
    />
  )
}
