/* eslint-disable @typescript-eslint/naming-convention */
'use client'

import { Icon } from '@/icons/icon'
import { type Maybe } from '@/lib/generated/graphql'
import { countdownTextCalculator } from '@/utils/time/count-down-text-calculator'

export default ({
  product_hideDate,
  product_startDate,
  product_endDate,
}: {
  product_hideDate?: boolean | null
  product_startDate?: Maybe<string>
  product_endDate?: Maybe<string>
}) => {
  const countDown = product_hideDate
    ? undefined
    : countdownTextCalculator(
        product_hideDate,
        product_startDate,
        product_endDate,
      )
  return countDown ? (
    <div
      className='flex items-center'
      data-testid='product-card-vertical-end-date'
    >
      <Icon name='time-clock-circle-bold' color='dark' size='xs' />
      <div className='ml-1 text-xs font-medium'>{countDown}</div>
    </div>
  ) : (
    <div className='h-4' />
  )
}
