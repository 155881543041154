import dayjs, { type Dayjs } from 'dayjs'

const dateTimeFormat = 'YYYY/MM/DD HH:mm:ss'
export const countdownTextCalculator = (
  hideEndDate: boolean | null | undefined,
  startDate?: string | null,
  endDate?: string | null,
  preFix?: string,
  addsHurry?: boolean,
) => {
  if (!startDate && !endDate) {
    return ''
  }
  const date = dayjs()
  const startDateCalculated = startDate
    ? dayjs(startDate, dateTimeFormat)
    : dayjs()
  const endDateCalculated = !!endDate && dayjs(endDate, dateTimeFormat)
  if (startDateCalculated > date) {
    return calculateStartDate(startDateCalculated, date, preFix)
  }

  if (endDateCalculated !== false && endDateCalculated > date) {
    return calculateEndDate(
      !!hideEndDate,
      endDateCalculated,
      date,
      preFix,
      addsHurry,
    )
  }
  return ''
}

const calculateEndDate = (
  hideEndDate: boolean,
  endDateCalculated: Dayjs,
  date: Dayjs,
  preFix?: string,
  addsHurry?: boolean,
) => {
  const totalMins = endDateCalculated.diff(date, 'm')

  const days = Math.floor(totalMins / 1440)
  const hours = Math.floor((totalMins % 1440) / 60)
  const minutes = Math.floor((totalMins % 1440) % 60)

  const hurry = addsHurry ? 'Hurry! ' : ''
  const ends = preFix ? `${preFix} ends` : 'Ends'

  if (hideEndDate) {
    return ''
  }

  if (days >= 1) {
    return `${hurry}${ends} in ${days}d ${hours}h`
  }

  if (hours >= 12) {
    if (minutes > 30) {
      return `${hurry}${ends} in ${hours}h 30m`
    }
    return `${hurry}${ends} in ${hours}h`
  }

  if (minutes > 0) {
    return `${hurry}${ends} in ${hours.toFixed(0)}h ${minutes.toFixed(0)}m`
  }
  return `${hurry}${ends} in ${hours.toFixed(0)}h`
}

const calculateStartDate = (
  startDateCalculated: Dayjs,
  date: Dayjs,
  preFix?: string,
) => {
  const starts = preFix ? `${preFix} starts` : 'Starts'
  const totalMins = startDateCalculated.diff(date, 'm')

  const days = Math.floor(totalMins / 1440)
  const hours = Math.floor((totalMins % 1440) / 60)
  const minutes = Math.floor((totalMins % 1440) % 60)

  if (days >= 2) {
    return `${starts} on ${startDateCalculated.format('DD/MM/YYYY')}`
  }
  if (days >= 1) {
    return `${starts} in 2 days`
  }
  if (hours >= 12) {
    return `${starts} tomorrow`
  }
  if (hours > 0) {
    return `${starts} in ${hours.toFixed(0)}h ${minutes.toFixed(0)}m`
  }
  return `${starts} in ${minutes.toFixed(0)}m`
}

export const countDownTextResponsive = (
  hideEndDate: boolean | null | undefined,
  startDate?: string | null,
  endDate?: string | null,
  preFix?: string,
  addsHurry?: boolean,
) => {
  if (!startDate && !endDate) {
    return ''
  }
  const date = dayjs()
  const startDateCalculated = startDate
    ? dayjs(startDate, dateTimeFormat)
    : dayjs()

  const endDateCalculated = !!endDate && dayjs(endDate, dateTimeFormat)

  if (startDateCalculated > date) {
    const starts = preFix ? `${preFix} starts` : 'Starts'
    const totalMins = startDateCalculated.diff(date, 'm')

    const days = Math.floor(totalMins / 1440)
    const hours = Math.floor((totalMins % 1440) / 60)

    return countdownTextMobile(false, `${starts}`, days, hours)
  }

  if (endDateCalculated !== false && endDateCalculated > date) {
    const calculateDays: Dayjs = endDateCalculated
    const totalMins = calculateDays.diff(date, 'm')

    const days = Math.floor(totalMins / 1440)
    const hours = Math.ceil((totalMins % 1440) / 60)
    const hurry = addsHurry ? 'Hurry! ' : ''
    const ends = preFix ? `${preFix} ends` : 'Ends'

    return countdownTextMobile(hideEndDate, `${hurry}${ends}`, days, hours)
  }
}

const countdownTextMobile = (
  hideEndDate: boolean | null | undefined,
  prefix: string,
  days: number,
  remaining: number,
) => {
  if (hideEndDate) {
    return `${prefix} soon`
  }
  if (days >= 1) {
    return `${prefix} in ${days}d`
  }
  return `${prefix} in ${remaining.toFixed(0)}h`
}
