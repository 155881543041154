'use client'

import { type Product } from '@/lib/generated/graphql'
import { hostURL } from '@/utils/functions/url-utils'
import { isNullOrEmpty } from '@/utils/string-extensions'
import * as Sentry from '@sentry/nextjs'

export const getProductClient = async (
  productId: string,
): Promise<Product | null> => {
  if (isNullOrEmpty(productId)) {
    return null
  }

  try {
    const resp = await fetch(`${hostURL()}/api/product/${productId}`, {
      method: 'GET',
    })

    return await resp.json()
  } catch (error) {
    // eslint-disable-next-line import/namespace
    Sentry.captureException(error)
  }
  return null
}
