/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable sonarjs/no-duplicate-string */
'use client'

import { type Breakpoint } from '@/components/utility/size/show-on-size'
import { type DiscountPriceProps } from '@/utils/functions/price-format'

import Link, { type LinkProps } from 'next/link'

import { sendGTMEvent } from '@next/third-parties/google'
import { type Maybe } from '@/lib/generated/graphql'
import { Suspense, type ReactNode } from 'react'
import { AddToCartTile } from '@/components/cart/add-to-cart-tile'
import dynamic from 'next/dynamic'
import { isNullOrEmpty } from '@/utils/string-extensions'
import { type AdvertData } from '@/lib/data/products/get-advert-server'

const ContdownText = dynamic(
  async () => await import('./product-card-footer-count-down'),
  {
    ssr: false,
  },
)

interface FooterProps {
  addToCartOnClick?: (variantId: string) => void
  buyNow?: boolean
  inWishList?: boolean | null | undefined
  vertical: boolean
  viewDetailsLink: string
  product_price?: Maybe<number>
  product_salePrice?: Maybe<number>
  product_id?: Maybe<string>
  product_brand?: Maybe<string>
  product_retailerId?: Maybe<string>
  product_name?: Maybe<string>
  product_categoryName?: Maybe<string>
  product_categoryId?: Maybe<string>
  product_advertLookUp?: Maybe<string>
  product_multiVariant?: Maybe<boolean>
  product_startDate?: Maybe<string>
  product_endDate?: Maybe<string>
  product_hideDate?: Maybe<boolean>
  product_imageUrl?: Maybe<string>
  eventNameOverride?: string
  overrideAddToCartEvent?: string
  available?: boolean
  advert: AdvertData
}

export interface HeightWidthBase {
  large: HeightWidth
  small: HeightWidth
}

export interface HeightWidth {
  height: number
  width: number
}

export interface ElementVerticalProps {
  vertical?: boolean
}

export interface SizeOverride {
  breakPointForSizeTransition: Breakpoint
  overrideWidth?: string | number
}

const getSmallFont = (breakpoint: Breakpoint) => {
  switch (breakpoint) {
    case 'sm':
      return 'sm:text-sm'
    case 'md':
      return 'md:text-sm'
    case 'xl':
      return 'xltext-sm'
    case 'xs':
      return ''
    default:
      return 'lg:text-sm'
  }
}

export interface SavingsLabelProps extends SizeOverride {
  prices: DiscountPriceProps
  'data-testid': string
}

export const Description = ({
  children,
  breakPointForSizeTransition,
  vertical,
  productId,
  description,
  brand,
  discountPrice,
  price,
  retailerId,
  eventNameOverride,
  ...props
}: {
  brand?: Maybe<string>
  breakPointForSizeTransition: Breakpoint
  children?: React.ReactNode
  description?: Maybe<string>
  discountPrice?: Maybe<number>
  price?: Maybe<number>
  productId?: Maybe<string>
  retailerId: Maybe<string>
  vertical?: boolean
  eventNameOverride?: string
} & LinkProps) => {
  if (children == null || (children as string).toString().length === 0)
    return null
  return (
    <Link
      className={`line-clamp-2 ${
        vertical
          ? 'mx-0 mb-1 mt-2 max-w-full'
          : ' mx-0 my-1 max-w-[calc(100%-20px)] self-start'
      } text-xs ${getSmallFont(breakPointForSizeTransition)} p-0 no-underline`}
      onClick={() => {
        sendGTMEvent({
          event: isNullOrEmpty(eventNameOverride)
            ? 'select_item'
            : eventNameOverride,
          ecommerce: {
            items: [
              {
                item_id: productId,
                item_name: description,
                currency: 'AUD',
                discount:
                  discountPrice != null && price != null
                    ? price - discountPrice
                    : 0,
                index: 0,
                item_brand: brand,
                item_category: '',
                item_category3: retailerId,
                item_category5: '',
                item_list_id: 'product_list',
                item_list_name: 'Products List',
                item_variant: '',
                location_id: window.location.href,
                price: discountPrice ?? price,
                quantity: 1,
              },
            ],
          },
        })
      }}
      {...props}
      prefetch={false}
    >
      {children}
    </Link>
  )
}

export const ImageColumn = ({
  children,
  vertical,
  breakPointForSizeTransition,
  productId,
  description,
  brand,
  discountPrice,
  price,
  retailerId,
  eventNameOverride,
  ...props
}: {
  children: ReactNode
  vertical?: boolean
  breakPointForSizeTransition: Breakpoint
  description?: Maybe<string>
  discountPrice?: Maybe<number>
  price?: Maybe<number>
  productId?: Maybe<string>
  retailerId: Maybe<string>
  brand?: Maybe<string>
  eventNameOverride?: string
} & LinkProps) => {
  let large = vertical
    ? 'lg:mb-2 lg:mt-1 lg:h-[208px] lg:min-w-[130px] lg:min-h-[140px]'
    : 'lg:m2 lg:w-[130px] lg:h-[130px] lg:min-w-[130px] lg:min-h-[130px]'

  switch (breakPointForSizeTransition) {
    case 'xl':
      large = vertical
        ? 'lg:mb-2 lg:mt-1 lg:h-[208px] lg:min-w-[130px] lg:min-h-[140px]'
        : 'lg:m2 lg:w-[130px] lg:h-[130px] lg:min-w-[130px] lg:min-h-[130px]'
      break
    case 'md':
      large = vertical
        ? 'md:mb-2 md:mt-1 md:h-[208px] md:min-w-[130px] md:min-h-[140px]'
        : 'md:m2 md:w-[130px] md:h-[130px] md:min-w-[130px] md:min-h-[130px]'
      break
    case 'sm':
      large = vertical
        ? 'sm:mb-2 sm:mt-1 sm:h-[208px] sm:min-w-[130px] sm:min-h-[140px]'
        : 'sm:m2 sm:w-[130px] sm:h-[130px] sm:min-w-[130px] sm:min-h-[130px]'
      break
    case 'xs':
      large = ''
      break
  }

  return (
    <Link
      className={` absolute border-none ${
        vertical
          ? 'left-0 top-2 my-1 h-[152px] min-h-[144px] w-full min-w-[144px] px-2'
          : 'left-2 top-2 m-2 h-[120px] min-h-[120px] w-[110px] min-w-[110px]'
      } ${large}`}
      {...props}
      onClick={() => {
        sendGTMEvent({
          event: isNullOrEmpty(eventNameOverride)
            ? 'select_item'
            : eventNameOverride,
          ecommerce: {
            items: [
              {
                item_id: productId,
                item_name: description,
                currency: 'AUD',
                discount:
                  discountPrice != null && price != null
                    ? price - discountPrice
                    : 0,
                index: 0,
                item_brand: brand,
                item_category: '',
                item_category3: retailerId,
                item_category5: '',
                item_list_id: 'product_list',
                item_list_name: 'Products List',
                item_variant: '',
                location_id: window.location.href,
                price: discountPrice ?? price,
                quantity: 1,
              },
            ],
          },
        })
      }}
      prefetch={false}
    >
      {children}
    </Link>
  )
}

const StyledFooter = ({
  children,
  vertical,
}: {
  children?: React.ReactNode
  vertical?: boolean
}) => {
  return (
    <div
      className={`absolute bottom-2 m-0 flex p-0 ${
        vertical ? 'mr-0 pb-2' : 'right-2 mr-2 pb-0'
      }`}
    >
      {children}
    </div>
  )
}

export const Footer = ({
  vertical = false,
  buyNow,
  inWishList,
  addToCartOnClick,
  viewDetailsLink,
  product_price,
  product_salePrice,
  product_id,
  product_brand,
  product_retailerId,
  product_name,
  product_categoryName,
  product_categoryId,
  product_advertLookUp,
  product_multiVariant,
  product_startDate,
  product_endDate,
  product_hideDate,
  product_imageUrl,
  eventNameOverride,
  overrideAddToCartEvent,
  available,
  advert,
}: FooterProps) => {
  const hasAdvertLookupId =
    product_advertLookUp !== undefined &&
    product_advertLookUp !== null &&
    product_advertLookUp !== ''
  const isSingleVariant = !product_multiVariant
  const canAddToCart = isSingleVariant && hasAdvertLookupId && buyNow

  const ViewDetailsLink = ({ available }: { available: boolean }) => (
    <Link
      prefetch={false}
      className={`flex items-center text-xs font-bold no-underline ${available && 'text-grey-600'}`}
      href={viewDetailsLink}
      onClick={() => {
        if (!(buyNow && addToCartOnClick)) {
          sendGTMEvent({
            ecommerce: null,
          })
          sendGTMEvent({
            event: isNullOrEmpty(eventNameOverride)
              ? 'select_item'
              : eventNameOverride,
            ecommerce: {
              items: [
                {
                  item_id: product_id,
                  item_name: product_name,
                  currency: 'AUD',
                  discount:
                    product_salePrice != null && product_price != null
                      ? product_price - (product_salePrice ?? 0)
                      : 0,
                  index: 0,
                  item_brand: product_brand,
                  item_category: product_categoryName,
                  item_category3: product_retailerId,
                  item_category5: product_categoryId,
                  item_list_id: 'product_list',
                  item_list_name: 'Products List',
                  location_id: window.location.href,
                  price: product_salePrice ?? product_price,
                  quantity: 1,
                },
              ],
            },
          })
        }
      }}
    >
      {available ? 'View Details' : 'Out of Stock'}
    </Link>
  )

  return (
    <>
      {!!product_id && canAddToCart && available === true ? (
        <AddToCartTile
          disabled={false}
          vertical={vertical}
          product_price={product_price}
          product_salePrice={product_salePrice}
          product_id={product_id}
          product_brand={product_brand}
          product_categoryName={product_categoryName}
          product_advertLookUp={product_advertLookUp}
          product_multiVariant={product_multiVariant}
          product_categoryId={product_categoryId}
          product_retailerId={product_retailerId}
          product_name={product_name}
          product_imageUrl={product_imageUrl}
          overrideAddToCartEvent={overrideAddToCartEvent}
          advert={advert}
        />
      ) : (
        <StyledFooter vertical={vertical}>
          <Suspense fallback={<div className='h-8' />}>
            {inWishList && (
              <ContdownText
                product_endDate={product_endDate}
                product_startDate={product_startDate}
                product_hideDate={product_hideDate}
              />
            )}
            <ViewDetailsLink available={available ?? false} />
          </Suspense>
        </StyledFooter>
      )}
    </>
  )
}
