import { type ReactNode } from 'react'
import { RepeaterStatic } from '../utility/pagination/pagination-list'

export interface CategoriesMenuProps {
  children: ReactNode[]
}

export const CategoriesMenuGrid = ({ children }: CategoriesMenuProps) => (
  <div
    data-testid='category-container'
    className='mt-6 flex w-full max-w-[calc(100%+48px)] justify-center'
  >
    <RepeaterStatic>{children}</RepeaterStatic>
  </div>
)
