'use client'

import {
  type FC,
  createContext,
  useContext,
  useState,
  type ReactNode,
} from 'react'

import WebPlayerModal from './web-player-dynamic'

interface WebPlayerContextProps {
  play: (url: string) => void
}

interface WebPlayerProviderProps {
  children?: ReactNode
}

const WebPlayerContext = createContext<WebPlayerContextProps>(
  {} as WebPlayerContextProps,
)

export const WebPlayerProvider: FC<WebPlayerProviderProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>()
  const [url, setUrl] = useState<string | undefined>()

  const play = (url: string) => {
    setOpen(true)
    setUrl(url)
  }

  const handlerPlayerOnClose = () => {
    setOpen(false)
  }

  const context: WebPlayerContextProps = {
    play,
  }

  return (
    <WebPlayerContext.Provider value={context}>
      {open && (
        <WebPlayerModal
          open={open ?? false}
          url={url}
          onClose={handlerPlayerOnClose}
        />
      )}
      {children}
    </WebPlayerContext.Provider>
  )
}

export const useWebPlayer = () => useContext(WebPlayerContext)
