'use client'

import { type ReactNode } from 'react'
import { type CircleSizes, getCircleSize } from './logo-utils'
import { sendGTMEvent } from '@next/third-parties/google'

import { CircleTile } from '@/components/utility/circle/circle-tile'

interface CircleLogoBasePropsGroup extends CircleLogoBase {
  backgroundColor: string
  alt: string
  underText?: string
  newLogo?: boolean
}

interface CircleBasePropsLink extends CircleLogoBasePropsGroup {
  link: string
}

interface CircleBasePropsClick extends CircleLogoBasePropsGroup {
  onClick: () => void
}

type CircleLogoBaseProps =
  | CircleBasePropsLink
  | CircleBasePropsClick
  | CircleLogoBasePropsGroup
interface CircleLogoBase {
  size?: CircleSizes
  /** sets up sizing for border show border turns it on and off */
  hasBorder?: boolean
  showBorder?: boolean
  variant?: CircleLogoVariants | CircleLogoVariantsEnum
  /* type information to send to GA */
  type?: string
  link?: string
  /* id information to send to GA */
  linkId?: string | undefined | null

  className?: string
}

interface CircleLogoSkeleton extends CircleLogoBase {
  hasText?: boolean
}

type CircleLogoImageProps = CircleLogoBaseProps & {
  src: string
  fill?: boolean
  sizes?: string
}

type CircleLogoComponentProps = CircleLogoBaseProps & {
  component: JSX.Element
}

type CircleLogoVariants = 'main' | 'user-preferences'

enum CircleLogoVariantsEnum {
  main = 'main',
  userPreferences = 'user-preferences',
}

export type CircleLogoProps = CircleLogoImageProps | CircleLogoComponentProps

const LogoText = ({ children }: { children: ReactNode }) => {
  return <div className='h-fit text-xs font-bold text-dark'>{children}</div>
}

const NewBorder = ({
  children,
  size,
  showBorder,
  ...props
}: {
  children: ReactNode
  size: CircleSizes
  showBorder?: boolean
}) => {
  let borderSize = ''

  switch (size) {
    case 'xs':
      borderSize = 'h-[29px] sm:h-[33px] w-[29px] sm:w-[33px]'
      break
    case 's':
      borderSize = 'h-[37px] sm:h-[41px] w-[37px] sm:w-[41px]'
      break
    case 'm':
      borderSize = 'h-[53px] sm:h-[57px] w-[53px] sm:w-[57px]'
      break
    case 'l':
      borderSize = 'h-[69px] sm:h-[73px] w-[69px] sm:w-[73px]'
      break
    case 'xl':
      borderSize = 'h-[83px] sm:h-[87px] w-[83px] sm:w-[87px]'
      break
    case 'xxl':
      borderSize = 'h-[133px] sm:h-[137px] w-[133px] sm:w-[137px]'
      break
    case 'xxxl':
      borderSize = 'h-[157px] sm:h-[161px] w-[157px] sm:w-[161px]'
      break
  }

  return (
    <div
      className={`${borderSize} ${
        showBorder ? 'border-secondary' : 'border-transparent'
      } flex shrink-0 items-center justify-center rounded-half border-[1.5px] border-solid`}
      {...props}
    >
      {children}
    </div>
  )
}

export const CircleSkeleton = ({
  size = 'm',
  hasBorder = false,
  hasText = false,
}: CircleLogoSkeleton) => {
  const properSize = getCircleSize(size)
  const InnerTile = () => (
    <div
      className='animate-pulse rounded-half bg-gray-200'
      style={{
        height: properSize,
        width: properSize,
      }}
      data-testid='circleLogoSkeleton'
    />
  )
  if (hasBorder) {
    return (
      <div className='flex cursor-pointer flex-col items-center justify-items-center p-1'>
        <NewBorder size={size} showBorder={false}>
          <InnerTile />
        </NewBorder>
        {hasText && (
          <div
            style={{
              width: properSize,
            }}
            className='h-4 animate-pulse bg-gray-200'
          />
        )}
      </div>
    )
  }
  return <InnerTile />
}

export const CircleLogo = ({
  size,
  newLogo,
  hasBorder = false,
  showBorder = false,
  variant = CircleLogoVariantsEnum.main,
  underText,
  link,
  linkId,
  type,
  className,
  ...props
}: CircleLogoProps) => {
  const resetProps = { size: getCircleSize(size) as number, ...props }
  if (type === 'retailer' && typeof window !== 'undefined') {
    sendGTMEvent({
      event: 'retailer_impression',
      retailerName: props.alt,
      location: window.location,
    })
  }

  const InnerTile = () => (
    <CircleTile
      href={link}
      className={className}
      {...resetProps}
      type={type}
      onClick={() => {
        sendGTMEvent({
          event: 'directory_search',
          clickText: props.alt,
          type,
          brandId: type === 'brand' ? linkId : '',
          retailerId: type === 'retailer' ? linkId : '',
          brandName: type === 'brand' ? props.alt : '',
          retailerName: type === 'retailer' ? props.alt : '',
          location: window.location.href,
        })

        if ('onClick' in resetProps) {
          resetProps.onClick()
        }
      }}
    />
  )
  if (hasBorder) {
    return (
      <div className='mb-2 flex cursor-pointer flex-col items-center justify-items-center p-1'>
        <NewBorder
          size={size as CircleSizes}
          showBorder={showBorder ?? newLogo}
          data-testid='circle-logo-border'
        >
          <InnerTile data-testid='inner-circle-logo' />
        </NewBorder>
        {newLogo && <LogoText>NEW</LogoText>}
        {underText && <LogoText>{underText}</LogoText>}
      </div>
    )
  }
  return <InnerTile />
}
