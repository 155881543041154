'use client'

import { useWebPlayer } from '@/components/web-player/web-player-context'
import { useRouter } from 'next/navigation'
import { type AdProps } from '@/lib/data/get-basic-ads'
import Image from 'next/image'
import { ResolvedImage } from '../utility/image'
import Link from 'next/link'

const AdvertisementInner = ({
  imgsrc,
  imgsrcSmall,
  alt,
  isCategoryBanner,
}: AdProps) => {
  if (isCategoryBanner === true) {
    return (
      <Image
        src={imgsrc ?? ''}
        alt={alt}
        title={alt}
        style={{
          borderRadius: '1.5rem',
        }}
        unoptimized
        fill
        priority
      />
    )
  }
  return (
    <>
      <ResolvedImage
        src={imgsrc ?? ''}
        alt={alt}
        title={alt}
        style={{
          borderRadius: '1.5rem',
        }}
        fill
        className='hidden sm:block'
      />
      <ResolvedImage
        src={imgsrcSmall ?? ''}
        alt={alt}
        title={alt}
        style={{
          borderRadius: '1.5rem',
        }}
        fill
        className='block sm:hidden'
      />
    </>
  )
}

export const Advertisement = ({
  imgsrc,
  imgsrcSmall,
  alt,
  action,
  params,
  isCategoryBanner,
  isLazy,
}: AdProps) => {
  const webPlayer = useWebPlayer()
  const router = useRouter()
  let linkUrl = ''
  if (action === 'link' && typeof params === 'string' && params !== '') {
    linkUrl = params
  }

  const handleOnclick = () => {
    switch (action) {
      case 'play':
        webPlayer.play(params as string)
        break

      case 'navigate':
        router.push(params as string)
        break

      case 'link':
        if (typeof params === 'string' && params !== '') {
          window.location.href = params
        }
        break

      case 'callback':
        if (typeof params === 'function') params()
        break
    }
  }
  if (action === 'link') {
    return (
      <div
        className='relative h-[150px] w-[180px] cursor-pointer rounded-3xl object-contain sm:w-[582px] md:h-[222px] md:w-[861px] lg:h-[288px] lg:w-[1117px]'
        onClick={handleOnclick}
      >
        <Link href={linkUrl}>
          <AdvertisementInner
            isCategoryBanner={isCategoryBanner}
            imgsrc={imgsrc}
            imgsrcSmall={imgsrcSmall}
            alt={alt}
            isLazy={isLazy}
          />
        </Link>
      </div>
    )
  }
  return (
    <div
      className='relative h-[150px] w-[180px] cursor-pointer rounded-3xl object-contain sm:w-[582px] md:h-[222px] md:w-[861px] lg:h-[288px] lg:w-[1117px]'
      onClick={handleOnclick}
    >
      <AdvertisementInner
        isCategoryBanner={isCategoryBanner}
        imgsrc={imgsrc}
        imgsrcSmall={imgsrcSmall}
        alt={alt}
        isLazy={isLazy}
      />
    </div>
  )
}
