export const CircularProgressBarSpin = ({
  className,
}: {
  className?: string
}) => (
  <div className={`${className} relative size-40 animate-spin `}>
    <svg className='size-full' viewBox='0 0 100 100'>
      <circle
        className='stroke-current text-transparent'
        strokeWidth='10'
        cx='50'
        cy='50'
        r='40'
        fill='transparent'
      ></circle>
      <circle
        className='progress-ring__circle  stroke-current text-primary'
        strokeWidth='10'
        strokeLinecap='round'
        cx='50'
        cy='50'
        r='40'
        fill='transparent'
        strokeDashoffset='calc(400 - (400 * 45) / 100)'
      ></circle>
    </svg>
  </div>
)
