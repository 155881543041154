/* eslint-disable @typescript-eslint/naming-convention */
'use client'

import {
  type ReactNode,
  createContext,
  useState,
  useCallback,
  useMemo,
} from 'react'
import {
  type Product,
  type Maybe,
  type WishList,
} from '@/lib/generated/graphql'
import { sendGTMEvent } from '@next/third-parties/google'
import { useLogin } from '../account/context/use-login'
import { Zoom, toast } from 'react-toastify'
import { getCategoryNameByProduct } from '@/utils/functions/category/utils/get-category-name'
import { WishListNotificationContent } from './wishlist-snackbar-notification'
import {
  addToWishlistClient,
  removeFromWishlistClient,
} from '@/lib/data/wishlist-queries'

interface WishListProviderProps {
  wishlist?: string[]
  setWishlist: (wishList: WishList) => void
  inWishList: (productId?: Maybe<string>) => boolean
  setIsOnWishList: (
    setIsInWishList: (value: boolean) => void,
    product?: Maybe<Product>,
  ) => void
}

export const WishlistContext = createContext<WishListProviderProps>({
  setWishlist: () => {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    throw new Error('WishlistProvider is not set')
  },
  inWishList: () => {
    return false
  },
  setIsOnWishList: () => {
    throw new Error('WishlistProvider is not set')
  },
})

export const WishlistProvider = ({ children }: { children: ReactNode }) => {
  const [wishlist, _setWishlist] = useState<string[]>()

  const { loggedIn, openLogin } = useLogin()

  const internalSetWishlist = useCallback(
    (newWishList: string[]) => {
      const AsubB = newWishList.every((a) => wishlist?.some((b) => a === b))
      const sameLength = newWishList.length === wishlist?.length

      if (!(AsubB && sameLength)) {
        _setWishlist(newWishList)
      }
    },
    [wishlist],
  )

  const setWishlist = useCallback(
    (newWishList: WishList) => {
      internalSetWishlist((newWishList.productIds ?? []) as string[])
    },
    [internalSetWishlist],
  )

  const inWishList = useCallback(
    (productId?: Maybe<string>) =>
      wishlist?.find?.((pId: Maybe<string>) => pId === productId) !==
        undefined ?? false,
    [wishlist],
  )

  const sendEvent = useCallback(
    (
      product: Maybe<Product>,
      eventDescriber: 'remove_from_wishlist' | 'add_to_wishlist',
    ) => {
      if (!product) return

      const { product_id, product_name, product_retailer } = product

      const categoryName = getCategoryNameByProduct(product)
      sendGTMEvent({
        event: eventDescriber,
        ecommerce: {
          currency: 'AUD',
          items: [
            {
              item: product_id,
              item_name: product_name,
              item_category: categoryName,
              item_category2: product_retailer?.retailer_name,
              item_category3: product_retailer?.retailer_id,
            },
          ],
        },
      })
    },
    [],
  )

  const setIsOnWishList = useCallback(
    async (
      setIsInWishList: (value: boolean) => void,
      product?: Maybe<Product>,
      // eslint-disable-next-line sonarjs/cognitive-complexity
    ) => {
      if (!product) {
        return
      }

      const { product_id, product_name, product_imageUrl } = product
      const isInWishList = inWishList(product_id)

      sendGTMEvent({
        ecommerce: null,
      })

      if (!loggedIn) {
        openLogin()
      } else if (isInWishList) {
        setIsInWishList(false)
        internalSetWishlist(wishlist?.filter((pId) => pId !== product_id) ?? [])

        try {
          const wishList = await removeFromWishlistClient(product_id)
          if (
            wishList?.removeFromWishList?.__typename === 'wishLists' &&
            wishList?.removeFromWishList?.items?.[0]?.__typename === 'wishList'
          ) {
            internalSetWishlist(
              (wishList?.removeFromWishList.items[0].productIds ??
                []) as string[],
            )
          }
        } catch {
          setIsInWishList(true)
        }
        sendEvent(product, 'remove_from_wishlist')
      } else {
        setIsInWishList(true)
        internalSetWishlist([...(wishlist ?? []), product_id as string])
        try {
          const wishList = await addToWishlistClient(product_id)
          if (
            wishList?.addToWishList?.__typename === 'wishLists' &&
            wishList?.addToWishList?.items?.[0]?.__typename === 'wishList'
          ) {
            internalSetWishlist(
              (wishList?.addToWishList.items[0].productIds ?? []) as string[],
            )
          }
          toast(
            <WishListNotificationContent
              src={product_imageUrl}
              text={`Saved ${product_name} to wishlist!`}
            />,
            {
              className: 'bg-success text-white w-fit rounded-lg',
              position: 'top-center',
              autoClose: 2000,
              transition: Zoom,
            },
          )
        } catch {
          setIsInWishList(false)
        }
        sendEvent(product, 'add_to_wishlist')
      }
    },
    [inWishList, internalSetWishlist, loggedIn, openLogin, sendEvent, wishlist],
  )

  const value = useMemo(
    () => ({
      wishlist,
      setWishlist,
      inWishList,
      setIsOnWishList,
    }),
    [wishlist, setWishlist, inWishList, setIsOnWishList],
  )

  return (
    <WishlistContext.Provider value={value}>
      {children}
    </WishlistContext.Provider>
  )
}
